import { Injectable } from '@angular/core';

import { environment } from "../environments/environment";

@Injectable()
export class UriConfig {

  baseApiUrl = environment.apiUrl;

  answerGroups = `${this.baseApiUrl}answer-groups`;

  auth = `${this.baseApiUrl}auth`;

  logout = `${this.baseApiUrl}auth/logout`;

  refresh = `${this.baseApiUrl}auth/refresh`;

  brands = `${this.baseApiUrl}brands`;

  user = `${this.baseApiUrl}vendors`;

  files = `${this.baseApiUrl}files`;

  login = `${this.baseApiUrl}auth/signin`;

  validateResetToken = `${this.baseApiUrl}auth/validate-reset-password`;

  resetPassword = `${this.baseApiUrl}auth/reset-password`;

  restorePassword = `${this.baseApiUrl}auth/restore-password`;

  changePassword = `${this.baseApiUrl}auth/change-password`;

  rounds = `${this.baseApiUrl}rounds`;

  sportEvents = `${this.baseApiUrl}sports-events`;

  externalSportEvents = `${this.baseApiUrl}sports-events/external`;

  answers = `${this.baseApiUrl}answers`;

  questionsList = `${this.baseApiUrl}questions/list`;

  questions = `${this.baseApiUrl}questions`;

  questionsAutoResulting = `${this.baseApiUrl}questions/auto-resulting`;

  teams = `${this.baseApiUrl}teams`;

  correctAnswers = `${this.baseApiUrl}answers/correct`;

  analytics = `${this.baseApiUrl}analytics`;

  analyticsReport = `${this.baseApiUrl}analytics/detailed`;

  analyticsMonthlyGameReport = `${this.baseApiUrl}analytics/monthly`;

  admins = `${this.baseApiUrl}admins`;

  vendors = `${this.baseApiUrl}vendors`;

  settings = `${this.baseApiUrl}settings`;

  leaderboards = `${this.baseApiUrl}leaderboards`;

  vendorAnalytic = `${this.baseApiUrl}analytics/vendor`;

  localizations = `${this.baseApiUrl}localizations`;

  locales = `${this.baseApiUrl}locales`;

  generateQR = `${this.baseApiUrl}auth/2fa/generate-qr`;

  verifyTwoFa = `${this.baseApiUrl}auth/2fa/verify`;

  loginTwoFa = `${this.baseApiUrl}auth/2fa/login`;

  deleteTwoFa = `${this.baseApiUrl}auth/2fa`;

  slackSettings =  `${this.baseApiUrl}app-settings`;

  roundCreationInstructions =  `${this.baseApiUrl}round-creation-instructions`;

  cmsContent =  `${this.baseApiUrl}cms/content`;

  cms =  `${this.baseApiUrl}cms`;

  infoPopupTabs =  `${this.baseApiUrl}info-popup-tabs`;

  questionsStreak = `${this.baseApiUrl}questions/streak`;

  streakRounds = `${this.baseApiUrl}rounds/streak`;

  upsellBanners = `${this.baseApiUrl}upsell-banners`;

  whitelistedUsers = `${this.baseApiUrl}maintenance-mode-white-list`;

  maintenanceMode = `${this.baseApiUrl}maintenance-mode`;

  popularBets = `${this.baseApiUrl}popular-bets`;

  sse = `${this.baseApiUrl}sse`;
}
