import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, map, Observable, of, switchMap, tap } from 'rxjs';

import { UriConfig } from "@app/app.config";
import { CurrencyAfterPriceEnum } from "@enums/CurrencyAfterPriceEnum";
import { PageSettingEnum } from "@enums/PageSettingEnum";
import { SettingsAvailabilityEnum } from "@enums/SettingsAvailabilityEnum";
import { ConditionalSettingsConfigItemModel, SettingsConfigItemModel } from "@models/SettingsConfigItemModel";
import { UserDetailsModel } from "@models/UserDetailsModel";

import { AuthService } from "./auth.service";
import { LocalStorageService } from "./local-storage.service";
import { LocalizationService } from "./localization.service";
import { ProfileService } from "./profile.service";


@Injectable({
  providedIn: 'root'
})
export class SaasSettingsService {

  settingConfigArray$ = new BehaviorSubject<SettingsConfigItemModel[]>([]);

  appInitialized$ = new BehaviorSubject(false);

  pageVisibilitySetting$ = new BehaviorSubject<{ [key: string]: boolean }>({});

  isAutoResultingEnabled$ = new BehaviorSubject(false);

  manualAutoResultingProcessing$ = new BehaviorSubject(false);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private profileService: ProfileService,
    private localizationService: LocalizationService
  ) {
  }

  getSettingsHash() {
    return this.http.get(this.uriConfig.settings + '/check-modifying/vendor');
  }

  getSettingsList(userId, filter?: {availability: Array<SettingsAvailabilityEnum>}): Observable<SettingsConfigItemModel[]> {
    let params = new HttpParams();
    if (filter?.availability) {
      filter.availability.forEach((availability) => {
        params = params.append('availability', availability);
      })
    }

    return this.http.get<SettingsConfigItemModel[]>(this.uriConfig.settings + `/${userId}`, { params });
  }

  updateSetting(setting: SettingsConfigItemModel | ConditionalSettingsConfigItemModel) {
    return this.http.patch(this.uriConfig.settings + `/${setting.id}`, setting);
  }

  createSetting(setting: SettingsConfigItemModel) {
    return this.http.post(this.uriConfig.settings, { items: [ setting ] });
  }

  /**
   * Load config for application
   * Get hash from BE and get hash from local storage, compare those hash, and if they are the same,
   * and config stored in local storage return current config
   * If there is no login user return nothing
   * If hash are not equal get and store new config
   * As we need user id for get config, before it we need get user data
   */
  loadConfig(): Observable<any> {
    this.appInitialized$.next(true);

    return this.authService.getCurrentUser()
      .pipe(
        switchMap((user) => {
          if (!user) {
            this.appInitialized$.next(false);
            return of({});
          }

          const role = this.authService.getCurrentRole();

          if (!role) {
            this.appInitialized$.next(false);
            return of({});
          }

          if (role === 'ADMIN') {
            this.appInitialized$.next(false);
            return this.profileService.fetchAdminInfo()
              .pipe(
                switchMap((user: UserDetailsModel) => {
                  this.profileService.currentUser$.next(user);
                  this.localStorageService.removeIsStreakTenant()
                  return of({});
                })
              );
          }

          return this.profileService.fetchUserInfo()
            .pipe(
              switchMap((currentUser: UserDetailsModel) => {
                this.profileService.currentUser$.next(currentUser);
                this.localStorageService.setIsStreakTenant(currentUser.isStreakVendor);
                if (currentUser) {
                  this.localizationService.fetchVendorLocale();
                }
                return this.getSettingsHash().pipe(
                  map((hashResponse: { hash: string }) => {
                    return [hashResponse.hash, currentUser.id]
                  })
                );
              }),
              switchMap(([hashResponse, id]: [string, number]) => {
                  const hash = this.localStorageService.getHash();
                  const savedSettingConfig = this.localStorageService.getSettingsConfig();
                  if (hashResponse === hash && savedSettingConfig) {
                    this.settingConfigArray$.next(savedSettingConfig);
                    this.pageVisibilitySetting$.next(this.getPageVisibilitySettings(savedSettingConfig));
                    return of(savedSettingConfig);
                  } else {
                    this.localStorageService.setHash(hashResponse)
                    return this.getSettingsList(
                      id,
                      { availability: [SettingsAvailabilityEnum.VD, SettingsAvailabilityEnum.BOTH] }
                    )
                      .pipe(
                        switchMap((config: SettingsConfigItemModel[]) => {
                          this.localStorageService.setSettingsConfig(config);
                          this.pageVisibilitySetting$.next(this.getPageVisibilitySettings(config));
                          this.settingConfigArray$.next(config);
                          return of(config);
                        }),
                      )
                  }
                }
              ),
              tap(config => {
                this.isAutoResultingEnabled$.next(this.isAutoResultingEnabled(config));
                this.manualAutoResultingProcessing$.next(this.isManualAutoResultingEnabled(config));
              }),
              finalize(() => this.appInitialized$.next(false))
            );
        })
      )
  }

  getPageVisibilitySettings(config) {
    if (!config || !config.length) return {};
    const pageVisibility: { [key: string]: boolean } = {}
    Object.values(PageSettingEnum).forEach(settingName => {
      pageVisibility[settingName] = config.find(configItem => configItem.key === settingName)?.value === 'true'
    })
    return pageVisibility;
  }

  getCurrencyAfterPriceSettings() {
    const config =  this.localStorageService.getSettingsConfig();
    if (!config || !config.length) return null;

    return config.find(configItem => configItem.key === CurrencyAfterPriceEnum.CURRENCY_AFTER_PRICE);
  }

  private isAutoResultingEnabled(config) {
    return config?.find(configItem => configItem.key === 'is_auto_resulting_enabled')?.value === 'true'
  }

  private isManualAutoResultingEnabled(config) {
    return config?.find(configItem => configItem.key === 'is_auto_resulting_verification_enabled')?.value === 'true'
  }
}
